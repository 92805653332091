<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--<div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from "@/core/DataServices";
import formEdit from "./add";
import moment from "moment-timezone";

//import firebase from "../../../../firebase.json";
export default {
  components: {
    formEdit,
  },
  data() {
    return {
      validationErrors: [],
      title: "Thêm Mới chi phí Khác",
      mode: "add",
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction: null,
      time_zone_local : moment.tz.guess(true),
    };
  },
  async mounted() {
    this.mode = this.$route.name === "editOtherCosts" ? "edit" : this.mode;
    this.title = this.mode === "edit" ? "Chỉnh sửa chi phí Khác" : this.title;
    if (!this.$commonFuction.isEmpty(this.$route?.params?.id)) {
      await this.getData(this.$route?.params?.id);
    }
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(
      this.$constants.KEY_MENU.LIST_OTHER_COSTS
    );
    if (this.mode == "edit") {
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    } else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: "/admin/list-other_costs" });
    },
    convertPhone(numberPhone) {
      var phone = numberPhone;
      if (numberPhone[0] === "0") {
        phone = phone.substring(1);
      }
      phone = phone.replace(" ", "");
      phone = "+84" + phone;
      return phone;
    },
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {
        var uid = this.$store.getters.user;
        var objects = {
          company_id: params.company_id,
          date_costs: moment.tz(params.date_costs, this.time_zone_local).format("yyyy-MM-DD"),
          active: params.active,
          updated_at: moment(new Date()).tz("Asia/Ho_Chi_Minh").toDate(),
          updated_by: uid
        };
        var other_costs_service = params.other_costs_service;
        var that = this;
        if (this.mode === "add") {
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
          await that.$apollo
          .mutate({
            mutation: DataServices.insertData("other_costs"),
            variables: {
              objects: dataInsert,
            },
          })
          .then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                for (let i=0; i < other_costs_service.length; i++) {
                    other_costs_service[i].other_costs_id = returning.id;
                }
                let variables = {
                    objects: other_costs_service
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('other_costs_service'),
                    variables: variables
                }).then(() => {
                    this.redirectEdit();
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
            }
          }).catch(async error => {
              console.log('error', error);
              this.$toast.add({
                  severity: 'error',
                  summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
              });
              this.$commonFuction.is_loading(false);
          });
        } else {
          // var user_id = this.$route.params.id;
          let variables = {
            data_update: objects,
            where_key: {
              id: { _eq: this.models.id },
            },
          };
          await this.$apollo
            .mutate({
              mutation: DataServices.updateData("other_costs"),
              variables: variables,
            })
            .then(async (res) => {
              if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                await this.$apollo.mutate({
                    mutation: DataServices.deleteData('other_costs_service'),
                    variables: {
                        'where_key': {
                            'other_costs_id': {'_eq': this.models.id}
                        }
                    }
                })
                for (let i=0; i < other_costs_service.length; i++) {
                  other_costs_service[i].other_costs_id = returning.id;
                }
                let variables = {
                    objects: other_costs_service
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('other_costs_service'),
                    variables: variables
                }).then(() => {
                    this.redirectEdit();
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
            }
          })
          .catch(async (error) => {
            console.log("error", error);
            that.$toast.add({
              severity: "error",
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000,
            });
            this.$commonFuction.is_loading(false);
            // this.summitEvent = false;
          });
        }
      }
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList('other_costs',{"fields" : `id`}),
        variables: {
          where_key: {
            id: { _eq: id }
          },
        },
        fetchPolicy: "network-only",
      });
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        console.log(this.models)
      }
      this.componentKey++;
    },
    
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-other_costs'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        // if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          // router_back.query = { page_transaction: that.page_transaction.toString() };
        // }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
  },
  apollo: {
  },
};
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only {
      padding: 0 !important;
    }
  }
}
.note {
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 24px;
  padding-top: 10px;
}
.p-formgrid {
  margin: 0;
  .p-panel-header {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    background: #eeeeee;
  }
  .p-card-field {
    padding: 24px;
    .p-field {
      .p-col-fixed {
        width: 300px;
        display: inline-block !important;
        margin: 0;
      }
      .p-col-width {
        width: 500px !important;
        border-radius: 5px;
        &.disabled {
          background: #eeeeee;
          border: 0 none;
          padding: 8px 10px;
        }
        .file-select {
          margin: 0;
          input[type="file"] {
            display: none;
          }
          .select-button {
            display: inline-block;
            cursor: pointer;
            background: transparent;
            border-radius: 5px;
            color: #000000;
            font-size: 13px;
            padding: 3px 16px;
            border: 1px solid #b4b4b4;
          }
        }
        &.pac-target-input {
          height: 80px;
          word-break: break-all;
          overflow-wrap: break-word;
        }
      }
      .box-images {
        margin-top: 10px;
        position: relative;
        .item-img {
          height: 150px;
          margin-right: 8px;
          margin-bottom: 8px;
          display: inline-flex;
          position: relative;
          border: 0.5px solid #ccc;
          &.p-col-5 {
            width: 18%;
          }
          img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            object-fit: contain;
          }
          .button-clear {
            width: 18px;
            height: 18px;
            background: #f5564e;
            float: right;
            cursor: pointer;
            position: absolute;
            right: 0px;
            img {
              vertical-align: top;
            }
          }
        }
      }
      .p-invalid {
        display: block;
      }
    }
  }
  .fixed-bottom-toolbar {
    background: transparent;
    border: 0 none;
    margin-top: 22px;
    border-top: 1px solid #b4b4b4;
    padding: 0;
    padding-top: 30px;
    .p-button {
      width: auto;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
       display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 50px;
}
.box-check-box {
  position: relative;
}
.box-check-box label {
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day {
  position: absolute;
  right: 0px;
  top: 2px;
}
</style>
